import { Logo } from "./components/logo/logo";
import "./styles/index.scss"

function App() {
  return (
   <div className="app-wrapper">
     <Logo />
   </div>
  );
}

export default App;
